@use '@angular/material' as mat;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$vyv-angular-primary: mat.define-palette(mat.$indigo-palette);
$vyv-angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$vyv-angular-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$vyv-angular-theme:  mat.define-light-theme((
  color: (
    primary: $vyv-angular-primary,
    accent: $vyv-angular-accent,
    warn: $vyv-angular-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($vyv-angular-theme);
@import "../../../node_modules/angular-calendar/css/angular-calendar.css";
@import "../../../node_modules/flatpickr/dist/flatpickr.css";
@import 'bootstrap';
@import './bootstrap-extended';
@import './colors';
@import './components';
@import "./themes/layout-dark";
@import "./core/menu/horizontal-menu";
@import './overrides';

