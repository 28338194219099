.carousel-inner > .carousel-item > img {
    max-width: 100%;
    height: auto;
}
.carousel-indicators [data-bs-target] {
  filter: invert(50%)  !important;
}
.carousel-indicators [data-bs-target] {
  filter: invert(50%)  !important;
}
.carousel-inner{
  background-color: transparent ;
}
.carousel-control-prev-icon {
  position:relative;
  background-color: transparent ;
  background-image: none;
}
.carousel-control-next-icon {
  position:relative;
  background-color: transparent ;
  background-image: none;
}
.carousel-control-prev-icon .ng-star-inserted span{
  background-color: transparent ;
  background-image: none;
}
.carousel-control-next-icon .ng-star-inserted span{
  background-color: transparent ;
  background-image: none;
}

.carousel-control-prev-icon span{
  background-color: transparent ;
  background-image: none !important;
}
.carousel-control-next-icon span{
  background-color: transparent ;
  background-image: none !important;
}
.carousel-control-prev-icon:before {
  content: "\f100";  /* this is your text. You can also use UTF-8 character codes as I do here */
  font-family: FontAwesome;
  left:-5px;
  position:absolute;
  top:0;
  font-weight: 900;
  font-size: 60px;
  filter: invert(50%);
}
.carousel-control-next-icon:before {
  content: "\f101";  /* this is your text. You can also use UTF-8 character codes as I do here */
  font-family: FontAwesome;
  left:-5px;
  position:absolute;
  top:0;
  font-weight: 900;
  font-size: 60px;
  filter: invert(50%);
}
